import React, { FC, useMemo, useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import ContactInfo from 'containers/VerticalPage/common/ContactInfo/ContactInfo'
import { tabsIds, getTabsList, activityTypes, overviewMapping } from './utils'
import Tabs from 'components/common/Tabs'
import Overview from '../Overview/Overview'
import Activity from '../Activity/Activity'
import DetailsWrapper from 'containers/VerticalPage/common/DetailsWrapper/DetailsWrapper'
import GraphContainer from 'containers/VerticalPage/common/GraphContainer/GraphContainer'
import { fetchEcommerceTabsInfo } from 'services/verticalsApi'
import type { CurrentZone } from 'utils/types'
import { selectCurrentZone } from 'store/global'
import styles from 'containers/VerticalPage/common/styles.scss'
import { ContactInfoSections, IrrelevantContactSections } from 'containers/VerticalPage/common/types'

const tabsList = getTabsList()
const irrelevantContactSections: IrrelevantContactSections = [ContactInfoSections.wallets]

const Details: FC = () => {
  const currentZone: CurrentZone = useSelector(selectCurrentZone)
  const { entityId } = useParams<{ entityId: string }>()

  const [activeTab, setActiveTab] = useState<number | null>(null)
  const [disabledTabIds, setDisabledTabIds] = useState<number[]>([])

  const handleTabClick = (event: React.SyntheticEvent<Element, Event>, value: number) => {
    setActiveTab(value)
  }

  const contentByTabMap = useMemo(
    () => ({
      [tabsIds.OVERVIEW]: <Overview />,
      [tabsIds.SOLD_ITEMS]: <Activity activityType={activityTypes.SOLD_ITEM} />,
      [tabsIds.REVIEWS]: <Activity activityType={activityTypes.REVIEW} />,
      [tabsIds.CONTACT_INFO]: <ContactInfo irrelevantSections={irrelevantContactSections} />,
    }),
    [],
  )

  const fetchTabsInfo = useCallback(async () => {
    if (currentZone) {
      const tabsInfo = await fetchEcommerceTabsInfo(
        entityId,
        currentZone.ecommerceIndices,
        currentZone.ecommerceActivityIndices,
      )

      setActiveTab(tabsInfo.activeTab)
      setDisabledTabIds(tabsInfo.disabledTabIds)
    }
  }, [currentZone, entityId])

  useEffect(() => {
    fetchTabsInfo()
  }, [fetchTabsInfo])

  return (
    <DetailsWrapper>
      {typeof activeTab === 'number' && (
        <>
          <div className={styles.tabsWrapper}>
            <Tabs
              tabs={tabsList}
              value={activeTab}
              disabledIds={disabledTabIds}
              tabsClasses={{
                root: styles.root,
                flexContainer: styles.flexContainer,
              }}
              tabClasses={{
                root: styles.tabRoot,
                disabled: styles.tabDisabled,
              }}
              onChange={handleTabClick}
            />
            {currentZone?.ecommerceGraphIndices && (
              <GraphContainer zone={currentZone.ecommerceGraphIndices} overviewMapping={overviewMapping} />
            )}
          </div>
          {contentByTabMap[activeTab]}
        </>
      )}
    </DetailsWrapper>
  )
}

export default Details
