import styles from 'components/BusinessesList/BusinessesList.scss'

enum GRID_COLUMNS_GROUPS {
  GROUP_SELECTION = 'selection',
  GROUP_DETAILS = 'identificationDetails',
  GROUP_LOCATION = 'taxLocation',
  GROUP_INCOME = 'income',
}

export enum COLUMNS_KEYS {
  SELECT = 'select',
  NAME = 'businessName',
  UNTAXED_INCOME = 'untaxedIncome',
  BUSINESS_STRUCTURE = 'businessStructure',
  DATA_SOURCES = 'dataSources',
  BUSINESS_TYPE = 'businessType',
  BUSINESS_ADDRESS = 'businessAddress',
  OWNERS = 'owners',
  TIN = 'tin',
  LOCATION = 'location',
  NUMBER_OF_EMPLOYEES = 'numberOfEmployees',
  NUMBER_OF_REVIEWS = 'numberOfReviews',
  NUMBER_OF_VISITORS = 'numberOfVisitors',
  MARKETING_EFFORTS = 'marketingEfforts',
  DOCUMENTED_INCOME = 'documentedIncome',
  REPORTED_INCOME = 'reportedIncome',
  REPORTED_STATUS = 'reportedStatus',
}

enum GROUP_HEADERS {
  IDNETIFICATION_DETAILS = 'Identification Details',
  TAX_LOCATION = 'Tax Location',
  REVENUE = 'Revenue',
}

const { IDNETIFICATION_DETAILS, TAX_LOCATION, REVENUE } = GROUP_HEADERS

const {
  SELECT,
  NAME,
  UNTAXED_INCOME,
  BUSINESS_STRUCTURE,
  DATA_SOURCES,
  BUSINESS_TYPE,
  BUSINESS_ADDRESS,
  OWNERS,
  TIN,
  LOCATION,
  NUMBER_OF_EMPLOYEES,
  NUMBER_OF_REVIEWS,
  NUMBER_OF_VISITORS,
  MARKETING_EFFORTS,
  DOCUMENTED_INCOME,
  REPORTED_INCOME,
  REPORTED_STATUS,
} = COLUMNS_KEYS

export interface GridColumns {
  key: COLUMNS_KEYS
  fixedWidth: number
  headerClassName: string
  headerIcons: string[]
  isHiddenByDefault: boolean
  group: GRID_COLUMNS_GROUPS
  size: number
  isPersistent: boolean
  groupHeader: GROUP_HEADERS
  firstInGroup: boolean
  isSearchable: boolean
  minWidth: number
}

const { GROUP_SELECTION, GROUP_DETAILS, GROUP_LOCATION, GROUP_INCOME } = GRID_COLUMNS_GROUPS

export const UNTAXED_REVENUE_COLUMN = UNTAXED_INCOME

export const GRID_COLUMNS: Partial<GridColumns>[] = [
  {
    key: UNTAXED_INCOME,
    fixedWidth: 200,
    headerClassName: styles.untaxedIncomeHeader,
    headerIcons: ['arrowUp', 'info'],
    isHiddenByDefault: true,
  },
  {
    group: GROUP_SELECTION,
    key: SELECT,
    size: 1,
    fixedWidth: 80,
    isPersistent: true,
  },
  {
    group: GROUP_DETAILS,
    key: NAME,
    groupHeader: IDNETIFICATION_DETAILS,
    firstInGroup: true,
    fixedWidth: 320,
    isSearchable: true,
    isPersistent: true,
  },
  {
    group: GROUP_DETAILS,
    key: REPORTED_STATUS,
    size: 2,
    fixedWidth: 250,
    isHiddenByDefault: String(process.env.PRESENT_LOTTO_INFORMATION).toLowerCase() !== 'true',
  },
  {
    group: GROUP_DETAILS,
    key: BUSINESS_STRUCTURE,
    size: 3,
    fixedWidth: 170,
    isPersistent: true,
  },
  {
    group: GROUP_DETAILS,
    key: DATA_SOURCES,
    size: 2,
    minWidth: 260,
  },
  {
    group: GROUP_DETAILS,
    key: BUSINESS_TYPE,
    fixedWidth: 180,
    isPersistent: true,
  },
  {
    group: GROUP_DETAILS,
    key: BUSINESS_ADDRESS,
    size: 4,
    minWidth: 320,
  },
  {
    group: GROUP_DETAILS,
    key: OWNERS,
    size: 2,
    minWidth: 200,
    isSearchable: true,
    isPersistent: true,
  },
  {
    group: GROUP_DETAILS,
    key: TIN,
    size: 2,
    minWidth: 140,
  },
  {
    group: GROUP_LOCATION,
    key: LOCATION,
    groupHeader: TAX_LOCATION,
    firstInGroup: true,
    size: 2,
    minWidth: 160,
  },
  {
    group: GROUP_LOCATION,
    key: NUMBER_OF_EMPLOYEES,
    size: 2,
    minWidth: 160,
  },
  {
    group: GROUP_LOCATION,
    key: MARKETING_EFFORTS,
    size: 2,
    minWidth: 160,
    isHiddenByDefault: true,
  },
  {
    group: GROUP_INCOME,
    key: DOCUMENTED_INCOME,
    groupHeader: REVENUE,
    firstInGroup: true,
    size: 2,
    minWidth: 180,
    isHiddenByDefault: false,
  },
  {
    group: GROUP_INCOME,
    key: REPORTED_INCOME,
    size: 2,
    minWidth: 180,
    isHiddenByDefault: true,
  },
  {
    group: GROUP_INCOME,
    key: NUMBER_OF_VISITORS,
    size: 2,
    minWidth: 180,
    isHiddenByDefault: true,
  },
  {
    group: GROUP_INCOME,
    key: NUMBER_OF_REVIEWS,
    size: 2,
    minWidth: 180,
  },
]

export const searchableColumns: (COLUMNS_KEYS | undefined)[] = GRID_COLUMNS.filter(column => column!.isSearchable).map(
  column => column.key,
)

export const toggleableColumns: (COLUMNS_KEYS | undefined)[] = GRID_COLUMNS.filter(column => !column.isPersistent).map(
  column => column.key,
)

export const defaultDisplayedColumns: (COLUMNS_KEYS | undefined)[] = GRID_COLUMNS.filter(
  column => !column.isPersistent && !column.isHiddenByDefault,
).map(column => column.key)
