const tooltipStyles = {
  tooltip: {
    position: 'relative',
    display: 'inline-block',
    cursor: 'pointer',
    borderRadius: '4px',
    fontSize: '0.95rem',
    padding: '0.4rem 0.8rem',
    textAlign: 'center',
    willChange: 'transform',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
    backgroundColor: '#333',
    color: '#fff',
  },
  tooltipLight: {
    backgroundColor: '#fff',
    color: '#000',
    border: '1px solid #dad8df',
    boxShadow: 'none',
    borderRadius: '8px',
  },
  tooltipArrow: {
    color: '#333',
  },
  tooltipArrowLight: {
    color: '#fff',
    '&::before': {
      content: '""',
      border: '1px solid #dad8df',
    },
  },
}

export default tooltipStyles
