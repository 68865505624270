import React, { FC, useMemo } from 'react'
import { isNumber } from 'lodash'
import Tooltip from 'components/Tooltip'
import { STREntityForTable } from 'containers/STRTablePage/types'
import VerticalTableBase from 'components/verticals/VerticalTableBase/VerticalTableBase'
import { VerticalTableCMPBaseProps } from 'components/verticals/VerticalTableBase/types'
import TableText from 'components/common/TableText'
import { formattedDate } from 'utils/formatters'
import { MRT_ColumnDef } from 'material-react-table'
import { BaseEntityForTable, TABLE_TEXT_TYPES } from 'utils/types'
import { useBaseCellStyles } from 'components/verticals/VerticalTableBase/useBaseCellStyles'
import { NOT_AVAILABLE } from 'utils/constants'
import { extractRootDomainNoExt } from 'containers/NFT/specs'
import BusinessDataSources from 'components/BusinessDataSources/BusinessDataSources'
import ReportedStatus from '../../ReportedStatus/ReportedStatus'
import NoData from 'components/NoData/NoData'

const STRTable: FC<VerticalTableCMPBaseProps<STREntityForTable>> = ({
  verticalEntities,
  fetchNextPage,
  isFetching,
  totalDBRowCount,
  totalFetched,
  sorting,
  setSorting,
  setMergedColumns,
  selectedColumns,
  allColumns,
}) => {
  const baseCellStyles = useBaseCellStyles()
  const revenueStyles = {
    muiTableHeadCellProps: {
      sx: { borderBottom: 'none' },
    },
    muiTableBodyCellProps: {
      sx: { ...baseCellStyles },
    },
    size: 150,
  }
  const columns = useMemo<MRT_ColumnDef<STREntityForTable>[]>(
    () => [
      {
        header: 'Identification Details',
        Header: <TableText textType={TABLE_TEXT_TYPES.groupHeader}>Identification details</TableText>,
        columns: [
          {
            id: 'totals.hostName.keyword',
            header: 'Profile name',
            Header: <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Profile name</TableText>,
            accessorFn: item => <TableText>{item.totals.hostName || NOT_AVAILABLE}</TableText>,
            ...revenueStyles,
            size: 200,
          },
          {
            id: 'entityDetails.name.keyword',
            header: 'Verified Name',
            Header: <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Verified Name</TableText>,
            accessorFn: item => <TableText>{item.entityDetails?.name || NOT_AVAILABLE}</TableText>,
            ...revenueStyles,
            size: 200,
          },
          {
            id: 'entityDetails.tin',
            header: 'TIN',
            enableSorting: false,
            Header: (
              <Tooltip title='The official tax identification number of the identified entity'>
                <TableText textType={TABLE_TEXT_TYPES.columnHeader}>TIN</TableText>
              </Tooltip>
            ),
            muiTableHeadCellProps: {
              sx: { borderBottom: 'none' },
            },
            muiTableBodyCellProps: {
              sx: { ...baseCellStyles },
            },
            size: 150,
            accessorFn: item => <TableText>{item?.entityDetails?.tin || 'TIN not found'}</TableText>,
          },
          {
            id: 'entityDetails.reportedStatus',
            header: 'Report Status',
            enableSorting: false,
            Header: (
              <Tooltip title='The status of the tax reporting in each tax year after comparing the tax return reportings with the documented revenue'>
                <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Report Status</TableText>
              </Tooltip>
            ),
            muiTableBodyCellProps: {
              sx: baseCellStyles,
            },
            size: 180,
            accessorFn: item =>
              item?.entityDetails?.reportedStatus ? (
                <ReportedStatus reportedStatus={item.entityDetails.reportedStatus} id={item.id} />
              ) : (
                <NoData />
              ),
          },
          {
            id: 'totals.activeListings',
            header: 'Active Listings',
            Header: <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Active Listings</TableText>,
            accessorFn: item => <TableText>{item.totals?.activeListings || NOT_AVAILABLE}</TableText>,
            ...revenueStyles,
            size: 200,
          },
          {
            id: 'totals.totalListings',
            header: 'Total Listings',
            Header: <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Total Listings</TableText>,
            accessorFn: item => <TableText>{item.totals?.totalListings || NOT_AVAILABLE}</TableText>,
            ...revenueStyles,
            size: 200,
          },
          {
            id: 'entityDetails.location.keyword',
            header: 'Host Location',
            Header: <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Host Location</TableText>,
            accessorFn: item => <TableText>{item.entityDetails.location || NOT_AVAILABLE}</TableText>,
            ...revenueStyles,
            size: 200,
          },
          {
            id: 'overviewDetails.firstActivity',
            enableSorting: false,
            header: 'First Activity',
            Header: (
              <Tooltip title='The date of the first documented business activity as identified by IVIX'>
                <TableText textType={TABLE_TEXT_TYPES.columnHeader}>First Activity</TableText>
              </Tooltip>
            ),
            muiTableBodyCellProps: {
              sx: baseCellStyles,
            },
            size: 180,
            accessorFn: item => (
              <TableText>
                {isNumber(item?.overviewDetails?.firstActivity) || /^\d{4}$/.test(item?.overviewDetails?.firstActivity)
                  ? item?.overviewDetails?.firstActivity
                  : formattedDate(item?.overviewDetails?.firstActivity, 'MMM. YYYY')}
              </TableText>
            ),
          },
        ],
      },
      {
        header: 'Activity Details',
        Header: <TableText textType={TABLE_TEXT_TYPES.groupHeader}>Activity Details</TableText>,
        columns: [
          {
            id: 'contactInfo.weblinks',
            header: 'Data Sources',
            enableSorting: false,
            Header: <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Data Sources</TableText>,
            muiTableBodyCellProps: {
              sx: { ...baseCellStyles },
            },
            size: 300,
            accessorFn: (item: BaseEntityForTable) => {
              const { contactInfo, entityDetails, revenueSources } = item
              if (contactInfo && entityDetails && revenueSources) {
                const platforms: string[] = []
                revenueSources.forEach(({ platform }) => {
                  if (!platforms.includes(platform?.toLowerCase())) {
                    platforms.push(platform?.toLowerCase())
                  }
                })
                const dataSources = Object.keys(contactInfo.weblinks || {})
                dataSources?.forEach((source: string) => {
                  if (!platforms.includes(source?.toLowerCase())) {
                    platforms.push(source?.toLowerCase())
                  }
                })
                entityDetails.profileUrls?.forEach((source: string) => {
                  const platform = extractRootDomainNoExt(source)
                  if (!platforms.includes(platform?.toLowerCase())) {
                    platforms.push(platform?.toLowerCase())
                  }
                })

                return <BusinessDataSources dataSources={platforms?.sort() || []} />
              }

              return NOT_AVAILABLE
            },
          },
        ],
      },
    ],
    [],
  )

  return (
    <VerticalTableBase<STREntityForTable>
      avoidBaseColumns
      columns={columns}
      items={verticalEntities}
      fetchNextPage={fetchNextPage}
      isFetching={isFetching}
      totalFetched={totalFetched}
      totalDBRowCount={totalDBRowCount}
      sorting={sorting}
      setSorting={setSorting}
      setMergedColumns={setMergedColumns}
      selectedColumns={selectedColumns}
      allColumns={allColumns}
    />
  )
}

export default STRTable
