import React, { useEffect, useContext, useState, useMemo, FC } from 'react'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import { useParams } from 'react-router-dom'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import InputBase from '@mui/material/InputBase'
import Box from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Graph from './Graph'
import { VerticalContext } from 'store/verticals/Context'
import { Modal } from 'components/Modal/Modal'
import Overview from '../Overview/Overview'
import Label from './Label'
import { getLabels, getTypes } from './utils'
import { useWindowSize } from 'hooks/useWindowSize'
import { IMapping } from '../Overview/Overview'
import styles from './styles'

const searchIconUrl = require('../../common/icons/search.svg')
const graphIconUrl = require('../../common/icons/graph.svg')

interface GraphContainer {
  zone: string
  overviewMapping: IMapping[]
}

const GraphContainer: FC<GraphContainer> = ({ zone, overviewMapping }) => {
  const { entityId } = useParams<{ entityId: string }>()
  const { graphData, getGraphData } = useContext(VerticalContext)
  const { height } = useWindowSize()

  const [popupIsOpen, setPopupIsOpen] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')

  useEffect(() => {
    if (!entityId || !zone) return
    const fetchGraphData = async () => {
      await getGraphData(entityId, zone)
    }
    fetchGraphData()
  }, [entityId, zone, getGraphData])

  const labels = useMemo(() => getLabels(graphData), [graphData])
  const types = useMemo(() => getTypes(graphData), [graphData])

  return graphData?.nodes.length > 0 && graphData?.edges.length > 0 ? (
    <Stack sx={styles.Container}>
      <Button sx={styles.Button} onClick={() => setPopupIsOpen(true)}>
        <Box component='img' src={graphIconUrl} alt='graph' mr={1} />
        Visual Graph
      </Button>
      <Modal isOpen={popupIsOpen} size='xl' onClose={() => setPopupIsOpen(false)} style={styles.Modal}>
        <Button sx={styles.CloseButton} onClick={() => setPopupIsOpen(false)}>
          <CloseRoundedIcon htmlColor='#8E8E93' />
        </Button>
        <Overview sxContainer={styles.OverviewContainer} overviewMapping={overviewMapping} />
        <Stack flexDirection='row'>
          <Stack sx={styles.GraphContainer}>
            {!!graphData?.nodes.length && !!graphData?.edges.length && (
              <Graph
                enableZoom
                nodes={graphData.nodes}
                edges={graphData.edges}
                style={{ width: '100%', height: `${height - 300}px` }}
              />
            )}
          </Stack>
          <Stack sx={styles.GraphOverviewContainer}>
            {/* <Stack sx={styles.SearchContainer}>
              <Box component='img' src={searchIconUrl} alt='Search' />
              <InputBase
                disabled
                placeholder='Search...'
                inputProps={{ 'aria-label': 'search' }}
                value={searchValue}
                onChange={e => setSearchValue(e.target.value)}
              />
            </Stack> */}
            {(labels.length > 0 || types.length > 0) && (
              <Stack sx={styles.GraphOverview}>
                <Typography sx={styles.GraphOverviewTitle}>Overview</Typography>
                <Divider sx={{ bgcolor: '#DAD8DF' }} />
                {labels.length > 0 && (
                  <Stack sx={styles.LabelsContainer}>
                    <Typography sx={styles.LabelsContainerTitle}>
                      Node labels
                      <span>|</span>
                      {labels.length}
                    </Typography>
                    <Stack flexDirection='row' flexWrap='wrap' gap={1} mt={1}>
                      {labels.map((label, index) => (
                        <Label key={index} data={label} borderRadius='16px' />
                      ))}
                    </Stack>
                  </Stack>
                )}
                {types.length > 0 && (
                  <Stack sx={{ ...styles.LabelsContainer, pt: '5px' }}>
                    <Typography sx={styles.LabelsContainerTitle}>
                      Relationship types
                      <span>|</span>
                      {types.length}
                    </Typography>
                    <Stack flexDirection='row' flexWrap='wrap' gap={1.5}>
                      {types.map((type, index) => (
                        <Label key={index} data={type} />
                      ))}
                    </Stack>
                  </Stack>
                )}
              </Stack>
            )}
          </Stack>
        </Stack>
      </Modal>
    </Stack>
  ) : (
    <></>
  )
}

export default GraphContainer
