import React, { FC, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import ContactInfo from 'containers/VerticalPage/common/ContactInfo/ContactInfo'
import { tabsIds, getTabsList, overviewMapping } from './utils'
import Tabs from 'components/common/Tabs'
import Activity from '../Activity/Activity'
import DetailsWrapper from 'containers/VerticalPage/common/DetailsWrapper/DetailsWrapper'
import GraphContainer from 'containers/VerticalPage/common/GraphContainer/GraphContainer'
import Listings from '../Listings/Listings'
import type { CurrentZone } from 'utils/types'
import { selectCurrentZone } from 'store/global'
import styles from 'containers/VerticalPage/common/styles.scss'
import { ContactInfoSections, IrrelevantContactSections } from 'containers/VerticalPage/common/types'

const tabsList = getTabsList()
const irrelevantContactSections: IrrelevantContactSections = [ContactInfoSections.wallets]

const Details: FC = () => {
  const currentZone: CurrentZone = useSelector(selectCurrentZone)

  const [activeTab, setActiveTab] = useState<number>(tabsIds.LISTINGS)

  const handleTabClick = (event: React.SyntheticEvent<Element, Event>, value: number) => {
    setActiveTab(value)
  }

  const contentByTabMap = useMemo(
    () => ({
      [tabsIds.LISTINGS]: <Listings />,
      [tabsIds.REVIEWS]: <Activity />,
      [tabsIds.CONTACT_INFO]: <ContactInfo irrelevantSections={irrelevantContactSections} />,
    }),
    [],
  )

  return (
    <DetailsWrapper>
      <div className={styles.tabsWrapper}>
        <Tabs
          tabs={tabsList}
          value={activeTab}
          tabsClasses={{
            root: styles.root,
            flexContainer: styles.flexContainer,
          }}
          tabClasses={{
            root: styles.tabRoot,
            disabled: styles.tabDisabled,
          }}
          onChange={handleTabClick}
        />
        {currentZone?.strGraphIndices && (
          <GraphContainer zone={currentZone.strGraphIndices} overviewMapping={overviewMapping} />
        )}
      </div>
      {contentByTabMap[activeTab]}
    </DetailsWrapper>
  )
}

export default Details
