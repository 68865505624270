const TableContainer = {
  p: 2.5,
  pt: 1.5,
  '.MuiTable-root': {
    whiteSpace: 'nowrap',
    '.MuiTableHead-root': {
      '.MuiTableRow-root': {
        '.MuiTableCell-root': {
          color: '#7E8BA6',
          fontFamily: 'Source Sans Pro',
          fontSize: '14px',
          fontWeight: 700,
          textTransform: 'uppercase',
          padding: { md: '0 10px', lg: '0 11px', xl: '0 13px' },
          border: 'none',
          lineHeight: { md: '15px', lg: '16px', xl: '18px' },
          '&:first-child': {
            paddingLeft: 0,
          },
          '&:last-child': {
            paddingRight: 0,
          },
        },
      },
    },
    '.MuiTableBody-root': {
      '.MuiTableRow-root': {
        cursor: 'pointer',
        '.MuiTableCell-root': {
          fontFamily: 'Source Sans Pro',
          fontSize: { md: '15px', lg: '15px', xl: '16px' },
          fontWeight: 400,
          color: '#1C2842',
          padding: { md: '13px 10px', lg: '14px 11px', xl: '16px 13px' },
          lineHeight: { md: '15px', lg: '16px', xl: '18px' },
          '&:first-child': {
            width: '150px',
          },
          '&:nth-child(2)': {
            width: '60px',
          },
        },
      },
    },
  },
}

const styles = {
  TableContainer,
}

export default styles
