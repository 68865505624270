import React, { FC, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { VerticalContext } from 'store/verticals/Context'
import Details from 'containers/VerticalPage/influencers/Details/Details'
import Content from 'containers/VerticalPage/influencers/Content/Content'
import Graph from 'containers/VerticalPage/common/Graph/Graph'
import { VerticalGraphLoader } from 'containers/VerticalPage/common/Loaders'
import { useSelector } from 'react-redux'
import { selectCurrentZone } from 'store/global'
import type { CurrentZone } from 'utils/types'
import VerticalDetailsContextProvider from 'store/verticalsDetails/Context'
import VerticalPageWrapper from 'containers/VerticalPage/common/VerticalPageWrapper/VerticalPageWrapper'
import { changeCurrentZoneIndexFunc } from 'containers/VerticalPage/common/utils'

const VerticalPage = (props: { changeCurrentZoneIndex: changeCurrentZoneIndexFunc }) => {
  const { graphData, getGraphData } = useContext(VerticalContext)
  const [isLoadingGraph, setIsLoadingGraph] = useState(false)
  const { entityId } = useParams<{ entityId: string }>()
  const [showGraph, setShowGraph] = useState<boolean>(false)
  const currentZone: CurrentZone = useSelector(selectCurrentZone)

  useEffect(() => {
    props.changeCurrentZoneIndex(currentZone?.influencersIndices)
  }, [currentZone])

  useEffect(() => {
    if (!entityId || !currentZone) return
    const fetchGraphData = async () => {
      setIsLoadingGraph(true)
      await getGraphData(entityId, currentZone.influencersGraphIndices)
      setIsLoadingGraph(false)
    }
    fetchGraphData()
  }, [entityId, currentZone, getGraphData])

  return (
    <VerticalPageWrapper
      graphElement={
        showGraph && (
          <>
            {isLoadingGraph ? (
              <VerticalGraphLoader />
            ) : (
              !!graphData?.nodes.length &&
              !!graphData?.edges.length && (
                <Graph nodes={graphData.nodes} edges={graphData.edges} setShowGraph={setShowGraph} />
              )
            )}
          </>
        )
      }
      gridContainerSx={{
        gridTemplateColumns: showGraph ? '62% calc(38% - 11px)' : '100%',
      }}
    >
      <Content />
      <VerticalDetailsContextProvider>
        <Details />
      </VerticalDetailsContextProvider>
    </VerticalPageWrapper>
  )
}

export default VerticalPage
