import React, { FC, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import ContactInfo from 'containers/VerticalPage/common/ContactInfo/ContactInfo'
import { tabsIds, getTabsList, overviewMapping } from './utils'
import Tabs from 'components/common/Tabs'
import Overview from '../Overview/Overview'
import BusinessActivity from '../BusinessActivity/BusinessActivity'
import DetailsWrapper from 'containers/VerticalPage/common/DetailsWrapper/DetailsWrapper'
import GraphContainer from 'containers/VerticalPage/common/GraphContainer/GraphContainer'
import { selectCurrentZone } from 'store/global'
import type { CurrentZone } from 'utils/types'
import styles from 'containers/VerticalPage/common/styles.scss'

const tabsList = getTabsList()

const Details: FC = () => {
  const [activeTab, setActiveTab] = useState<number>(tabsIds.OVERVIEW)
  const currentZone: CurrentZone = useSelector(selectCurrentZone)

  const handleTabClick = (event: React.SyntheticEvent<Element, Event>, value: number) => {
    setActiveTab(value)
  }

  const contentByTabMap = useMemo(
    () => ({
      [tabsIds.OVERVIEW]: <Overview />,
      [tabsIds.BUSINESS_ACTIVITY]: <BusinessActivity />,
      [tabsIds.CONTACT_INFO]: <ContactInfo />,
    }),
    [],
  )

  return (
    <DetailsWrapper>
      <div className={styles.tabsWrapper}>
        <Tabs
          tabs={tabsList}
          value={activeTab}
          disabledIds={[tabsIds.TAX_RETURN]}
          tabsClasses={{
            root: styles.root,
            flexContainer: styles.flexContainer,
          }}
          tabClasses={{
            root: styles.tabRoot,
            disabled: styles.tabDisabled,
          }}
          onChange={handleTabClick}
        />
        {currentZone?.gigGraphIndices && (
          <GraphContainer zone={currentZone.gigGraphIndices} overviewMapping={overviewMapping} />
        )}
      </div>
      {contentByTabMap[activeTab]}
    </DetailsWrapper>
  )
}

export default Details
