import React, { useMemo, useEffect } from 'react'

import { useBaseColumns } from 'components/verticals/VerticalTableBase/useBaseColumns'
import Loader from 'components/Loader/Loader'

import { BaseEntityForTable } from 'utils/types'
import { MaterialReactTable, useMaterialReactTable, MRT_ColumnDef, MRT_SortingState } from 'material-react-table'
import { FetchNextPageOptions, InfiniteQueryObserverResult } from '@tanstack/react-query'
import Box from '@mui/material/Box'

import { useBaseTableOptions } from './useBaseTableOptions'
import { mergeColumns } from './mergeColumns'
import { ISelectedColumn } from './types'
import { useSelectedRows } from 'store/selectedRows/SelectedRowsContext'
import { useWindowSize } from 'hooks/useWindowSize'

interface VerticalTableBaseProps<VerticalEntityType extends BaseEntityForTable> {
  columns: MRT_ColumnDef<VerticalEntityType>[]
  items: VerticalEntityType[] | null
  totalFetched: number
  isFetching: boolean
  totalDBRowCount: number
  verticalTooltipValues?: Record<string, string>
  avoidBaseColumns?: boolean
  sorting: MRT_SortingState
  setSorting: React.Dispatch<React.SetStateAction<MRT_SortingState>>
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<InfiniteQueryObserverResult<VerticalEntityType, unknown>>
  setMergedColumns?: React.Dispatch<React.SetStateAction<MRT_ColumnDef<VerticalEntityType>[]>>
  selectedColumns?: ISelectedColumn[]
  allColumns?: MRT_ColumnDef<VerticalEntityType>[]
}

const VerticalTableBase = <T extends BaseEntityForTable>({
  columns,
  items,
  isFetching,
  totalFetched,
  totalDBRowCount,
  verticalTooltipValues,
  avoidBaseColumns = false,
  sorting,
  setSorting,
  fetchNextPage,
  setMergedColumns,
  selectedColumns,
  allColumns,
}: VerticalTableBaseProps<T>) => {
  const baseColumns = useBaseColumns<T>({ verticalTooltipValues })

  const mergedColumns = useMemo(
    () => (avoidBaseColumns ? columns : mergeColumns(baseColumns, columns)),
    [baseColumns, columns, avoidBaseColumns],
  )

  useEffect(() => {
    if (setMergedColumns) {
      setMergedColumns(mergedColumns)
    }
  }, [mergedColumns, setMergedColumns])

  const visibleColumns =
    useMemo(() => {
      if (!selectedColumns) return allColumns
      const selectedColumnIds = selectedColumns.map((column: any) => column.value)
      return allColumns?.filter((column: any) => {
        return selectedColumnIds.includes(column.id)
      })
    }, [allColumns, selectedColumns]) || mergedColumns

  const tableOptions = useBaseTableOptions({
    columns: visibleColumns,
    items,
    isFetching,
    totalFetched,
    totalDBRowCount,
    sorting,
    setSorting,
    fetchNextPage,
  })

  const table = useMaterialReactTable(tableOptions)

  const { setSelectedRows } = useSelectedRows()

  useEffect(() => {
    const newSelectedRows = Object.keys(table.getState().rowSelection).map(rowId => table.getRow(rowId).original)
    setSelectedRows(newSelectedRows)
  }, [table.getState().rowSelection, table, setSelectedRows])

  const { height } = useWindowSize()
  const summaryHeight = document.querySelector('section#businessSummary')?.getBoundingClientRect()?.height || 0
  const navigationHeight = document.querySelector('nav#navigationHeaderWrapper')?.getBoundingClientRect()?.height || 0

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        height: height - (summaryHeight + navigationHeight + 230),
      }}
    >
      <MaterialReactTable table={table} />
      {isFetching && (
        <Box sx={{ position: 'absolute', bottom: '20px', left: '50%' }}>
          <Loader type='scrolling' />
        </Box>
      )}
    </Box>
  )
}

export default VerticalTableBase
