import React, { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import Box from '@mui/system/Box'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import CircularProgress from '@mui/material/CircularProgress'
import Map from 'components/Map/Map'
import { VerticalContext } from 'store/verticals/Context'
import { tableHeaders } from './utils'
import styles from './styles'
import { extractRootDomainNoExt } from 'containers/NFT/specs'
import { camelCase } from 'lodash'
import BusinessSource from 'components/BusinessSource'
import { ListingsLocations } from 'store/verticals/types'
import { selectMapBaseUrl, selectMapZoomDelta, selectPlatform } from 'store/global'

const Listings = () => {
  const { verticalEntity } = useContext(VerticalContext)
  const platform = useSelector(selectPlatform)
  const mapBaseUrl = useSelector(selectMapBaseUrl)
  const mapZoomDelta = useSelector(selectMapZoomDelta)

  const [selectedListingRow, setSelectedListingRow] = useState<ListingsLocations & { listingUrl: string }>()
  console.log('selectedListingRow', selectedListingRow)

  return (
    <Box
      sx={{
        bgcolor: 'white',
        gridRow: '2 / 3',
        overflow: 'hidden',
        display: 'flex',
        padding: '12px',
        borderRadius: '16px',
        gap: '12px',
      }}
    >
      {verticalEntity ? (
        <>
          {verticalEntity?.revenueSources?.length > 0 ? (
            <>
              <TableContainer sx={styles.TableContainer}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {tableHeaders.map(head => (
                        <TableCell key={head}>{head}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {verticalEntity?.revenueSources.map((row, index) => {
                      const domain = extractRootDomainNoExt(row.profileUrl)
                      return (
                        <TableRow
                          key={index}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                            ...(selectedListingRow?.listingId === row.profileName && Boolean(row.profileName)
                              ? { backgroundColor: '#007bff0a' }
                              : {}),
                          }}
                          onClick={() =>
                            setSelectedListingRow({
                              listingId: row?.profileName || 0,
                              listingAddressEnriched: row?.listingsLocations?.listingAddressEnriched || '',
                              coordinates: row?.listingsLocations?.coordinates || '',
                              listingUrl: row.profileUrl || '',
                              listingStateLocator: 'Virginia, United States',
                            })
                          }
                        >
                          <TableCell>{row?.profileName || 'N/A'}</TableCell>
                          <TableCell>
                            <a key={row.profileUrl} href={row.profileUrl} target='_blank' rel='noreferrer'>
                              <BusinessSource source={camelCase(domain)} isClickable />
                            </a>
                          </TableCell>
                          <TableCell>
                            {[
                              row?.listingsLocations?.listingAddressEnriched,
                              row?.listingsLocations?.listingStateLocator,
                            ]
                              .filter(str => Boolean(str))
                              .join(', ') || 'N/A'}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Map
                platform={platform}
                widgets={['zoomLevel']}
                baseUrl={mapBaseUrl}
                zoomDelta={mapZoomDelta}
                locations={[
                  {
                    list: [selectedListingRow],
                  },
                ]}
                pointsMode
              />
            </>
          ) : (
            <Stack>No Data</Stack>
          )}
        </>
      ) : (
        <Stack justifyContent='center' alignItems='center' sx={{ p: 3 }}>
          <CircularProgress />
        </Stack>
      )}
    </Box>
  )
}

export default Listings
