export enum tabsIds {
  LISTINGS,
  REVIEWS,
  CONTACT_INFO,
}

const tabsList = {
  LISTINGS: { id: tabsIds.LISTINGS, label: 'Listings' },
  REVIEWS: { id: tabsIds.REVIEWS, label: 'Reviews' },
  CONTACT_INFO: { id: tabsIds.CONTACT_INFO, label: 'Contact Info' },
}

export const getTabsList = () => [tabsList.LISTINGS, tabsList.REVIEWS, tabsList.CONTACT_INFO]

export const overviewMapping = [
  { key: 'activityType', label: 'Activity Type' },
  { key: 'firstActivity', label: 'First Activity' },
  { key: 'documentedRevenue', label: 'Total Revenue' },
  { key: 'reportedRevenue', label: 'Reported Revenue' },
  { key: 'taxGap', label: 'Unreported Revenue' },
]
