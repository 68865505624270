import axios from 'services/axios'
import qs from 'qs'
import apiService from './api'
import { FetchEOGraphDataProps } from 'containers/VerticalPage/company-overview/OverviewTab/types'
import { LineChartDatum } from 'components/common/types'
import { Filter } from 'components/FilterDrawer/FilterDrawer.config'

export const fetchVerticalEntity = async (entityId: string, zoneEndpoint: string) => {
  const endpoint = 'vertical'
  if (!entityId || !zoneEndpoint) return

  const params = {
    entityId,
    zoneEndpoint,
  }
  const config = {
    params,
  }

  try {
    const { data } = await axios.get(endpoint, config)
    return data
  } catch (err) {
    apiService.handleApiError(err as {} | undefined)
  }
}

export const exportSingleEntityCSV = async (entityId: string, zoneEndpoint: string) => {
  const endpoint = 'vertical/exportSingleEntityCSV'
  if (!entityId || !zoneEndpoint) return

  const body = {
    entityId,
    zoneEndpoint,
  }

  try {
    const { data } = await axios.post(endpoint, body)
    return data
  } catch (err) {
    apiService.handleApiError(err as object | undefined)
    throw new Error('Failed to fetch data')
  }
}

export const fetchVerticalDocumentActivity = async (
  entityId: string,
  filters: Record<string, string>[],
  from: number,
  zoneEndpoint: string,
  period?: { start: string; end: string },
  size?: number,
  activityType?: string | string[],
  sort?: string | qs.ParsedQs | string[] | qs.ParsedQs[],
) => {
  const endpoint = 'vertical/documentedActivity'
  if (!entityId || !zoneEndpoint) return

  const params = {
    entityId,
    zoneEndpoint,
    period,
    filters: JSON.stringify(filters),
    from,
    size,
    activityType,
    sort,
  }
  const config = {
    params,
  }

  try {
    const { data } = await axios.get(endpoint, config)
    return data
  } catch (err) {
    apiService.handleApiError(err as {} | undefined)
  }
}

export const fetchVerticalDocumentActivityStats = async (
  entityId: string,
  zoneEndpointActivity: string,
  zoneEndpointEntity: string,
  period?: { start: string; end: string },
  activityType?: string | string[],
  filters?: string,
) => {
  const endpoint = 'vertical/documentedActivityStats'
  if (!entityId || !zoneEndpointActivity || !zoneEndpointEntity) return

  const params = {
    entityId,
    zoneEndpointActivity,
    zoneEndpointEntity,
    period,
    activityType,
    filters,
  }
  const config = {
    params,
  }

  try {
    const { data } = await axios.get(endpoint, config)
    return data
  } catch (err) {
    apiService.handleApiError(err as {} | undefined)
  }
}

export const fetchVerticalRevenueBySources = async (
  entityId: string,
  period: { start: string; end: string },
  zoneEndpointActivity: string,
) => {
  const endpoint = 'vertical/financialsPieCharts'
  if (!entityId || !zoneEndpointActivity || !period) {
    console.error('Missing required params')
    return
  }

  const params = {
    entityId,
    zoneEndpoint: zoneEndpointActivity,
    period,
  }
  const config = {
    params,
  }

  try {
    const { data } = await axios.get(endpoint, config)

    return data
  } catch (err) {
    apiService.handleApiError(err as {} | undefined)
  }
}

export const fetchVerticalEstimatedRevenue = async (
  entityId: string,
  period: { start: string; end: string },
  zoneEndpointActivity: string,
) => {
  const endpoint = 'vertical/financialsBarCharts'
  if (!entityId || !zoneEndpointActivity || !period) {
    console.error('Missing required params')
    return
  }

  const params = {
    entityId,
    zoneEndpoint: zoneEndpointActivity,
    period,
  }
  const config = {
    params,
  }

  try {
    const { data } = await axios.get(endpoint, config)

    return data
  } catch (err) {
    apiService.handleApiError(err as {} | undefined)
  }
}

export const fetchGraphData = async (entityId: string, zoneEndpoint: string) => {
  if (!entityId || !zoneEndpoint) return

  const endpoint = 'graph'

  const params = {
    entityId,
    zoneEndpoint,
  }

  const config = {
    params,
  }

  try {
    const { data } = await axios.get(endpoint, config)
    return data
  } catch (err) {
    apiService.handleApiError(err as {} | undefined)
  }
}

export const fetchCompanyOverviewEmployeesData = async (
  caseId: string,
  from: number,
  zoneEndpoint: string,
  searchValue: string,
  filters: Filter[],
  size?: number,
) => {
  const endpoint = `companyOverview/${caseId}/employees`
  if (!caseId || !zoneEndpoint) return

  const params = {
    caseId,
    zoneEndpoint,
    filters,
    from,
    searchValue,
    size,
  }
  const config = {
    params,
  }

  try {
    const { data } = await axios.get(endpoint, config)
    return data
  } catch (err) {
    apiService.handleApiError(err as {} | undefined)
  }
}

export const fetchEmployeesOverviewChartData = async ({
  caseId,
  zoneEndpoint,
  localCountry,
  filters,
}: FetchEOGraphDataProps): Promise<LineChartDatum[] | undefined> => {
  const endpoint = `/companyOverview/${caseId}/employees/chart`
  const params = {
    zoneEndpoint,
    localCountry,
    filters,
  }
  const config = {
    params,
  }

  try {
    const { data } = await axios.get(endpoint, config)
    return data
  } catch (err) {
    apiService.handleApiError(err as {} | undefined)
  }
}

export const fetchSvgContent = async (url: string) => {
  const endpoint = `util/fetch-svg`
  if (!url) return

  const params = {
    url,
  }
  const config = {
    params,
  }

  try {
    const { data } = await axios.get(endpoint, config)
    return data
  } catch (err) {
    apiService.handleApiError(err as {} | undefined)
  }
}

export const fetchApiZones = async () => {
  const endpoint = '/tin/api-zones'

  try {
    const { data } = await axios.get(endpoint)
    return data
  } catch (err) {
    apiService.handleApiError(err as {} | undefined)
  }
}

export const fetchEcommerceTabsInfo = async (
  entityId: string,
  entityZoneEndpoint: string,
  documentedActivityZoneEndpoint: string,
) => {
  const endpoint = `/vertical/ecommerce-tabs-info?entityId=${entityId}&entityZoneEndpoint=${entityZoneEndpoint}&documentedActivityZoneEndpoint=${documentedActivityZoneEndpoint}`

  try {
    const { data } = await axios.get(endpoint)
    return data
  } catch (err) {
    apiService.handleApiError(err as {} | undefined)
  }
}
