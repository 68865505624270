import React, { useEffect, useState } from 'react'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'

import Header from 'components/Header'
import Navigation from 'components/Navigation'
import SettingsModal from 'components/SettingsModal'
import PlatformsModal from 'components/PlatformsModal'
import Loader from 'components/Loader'
import Alerts from 'containers/Alerts'
import Preview from 'containers/Preview/Loadable'
import ErrorPage from 'containers/ErrorPage/Loadable'
import LoginPage from 'containers/LoginPage/LoginPage'
import NotFoundPage from 'containers/NotFoundPage/Loadable'
import DashboardPage from 'containers/DashboardPage/Loadable'
import BusinessesPage from 'containers/BusinessesPage/Loadable'
import RentalsPage from 'containers/Rentals/'
import OrganizationsPage from 'containers/OrganizationsPage/Loadable'
import ProfilePage from 'containers/ProfilePage/Loadable'
import BusinessPage from 'containers/BusinessPage/Loadable'
import InfluencersTablePage from 'containers/InfluencersTablePage/InfluencersTablePage'
import EcommerceTablePage from 'containers/EcommerceTablePage/EcommerceTablePage'
import FreelancersTablePage from 'containers/FreelancersTablePage/FreelancersTablePage'
import NFTTablePage from 'containers/NFTTablePage/NFTTablePage'
import CDTFATablePage from 'containers/CDTFATablePage/CDTFATablePage'
import OffshoreTablePage from 'containers/OffshoreTablePage/OffshoreTablePage'
import FatcaTablePage from 'containers/FatcaTablePage/FatcaTablePage'
import ServiceProvidersTablePage from 'containers/ServiceProvidersTablePage/ServiceProvidersTablePage'
import InfluencersVerticalPage from 'containers/VerticalPage/influencers/VerticalPage/VerticalPage'
import EcommerceVerticalPage from 'containers/VerticalPage/ecommerce/VerticalPage/VerticalPage'
import STRVerticalPage from 'containers/VerticalPage/str/VerticalPage/VerticalPage'
import FreelancersVerticalPage from 'containers/VerticalPage/freelancers/VerticalPage/VerticalPage'
import NFTVerticalPage from 'containers/VerticalPage/nft/VerticalPage/VerticalPage'
import CDTFAVerticalPage from 'containers/VerticalPage/cdtfa/VerticalPage/VerticalPage'
import CompanyOverviewVerticalPage from 'containers/VerticalPage/company-overview/VerticalPage/VerticalPage'
import ServiceProvidersVerticalPage from 'containers/VerticalPage/serviceProviders/VerticalPage/VerticalPage'
import STRTablePage from 'containers/STRTablePage/STRTablePage'

import {
  configureMap,
  configurePlatform,
  configureStaticFiles,
  fetchCurrentUser,
  fetchTransmissions,
  fetchUserRoles,
  selectAvailableZones,
  selectCurrentUser,
  selectCurrentZone,
  selectIsGlobalLoading,
  selectIsLoggedIn,
  selectIsLoggingIn,
  setCurrentZone,
} from 'store/global'
import { selectIsModalDisplayed, selectIsUserPlatformsModalDisplayed } from 'store/settings'
import { setDisplayedColumns } from 'store/businesses'
import { setHostsDisplayedColumns } from 'store/rentals'
import VerticalContextProvider from 'store/verticals'

import localStorageService from 'services/storage'
import messages from './messages'
import PrivateRoute, { ROLES } from 'utils/PrivateRoute'

import { createTheme, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useDispatch, useSelector } from 'react-redux'
import { QueryParamProvider } from 'use-query-params'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { injectIntl } from 'react-intl'
import { useMatomo } from '@datapunt/matomo-tracker-react'

import { globalTheme } from 'styles/globalTheme'
import styles from './App.scss'
import CompanyOverviewTablePage from 'containers/CompanyOverviewTablePage/CompanyOverviewTablePage'
import ZoneApiWrapper from 'store/verticals/ContextWrapper'
import { SelectedRowsProvider } from 'store/selectedRows/SelectedRowsContext'
import ErrorBoundary from 'utils/ErrorBoundary/ErrorBoundary'

const queryClient = new QueryClient()

function App({ intl }) {
  const dispatch = useDispatch()
  const { pushInstruction } = useMatomo()
  const currentUser = useSelector(selectCurrentUser)
  const isLoggedIn = useSelector(selectIsLoggedIn)
  const isGlobalLoading = useSelector(selectIsGlobalLoading)
  const isLoggingIn = useSelector(selectIsLoggingIn)
  const isSettingsModalDisplayed = useSelector(selectIsModalDisplayed)
  const isUserPlatformsModalDisplayed = useSelector(selectIsUserPlatformsModalDisplayed)
  const availableZones = useSelector(selectAvailableZones)
  const location = useLocation()
  const { pathname = '', search } = location || {}
  const isAtLogin = pathname === '/' || pathname === '/login'

  const [currentZoneIndex, setCurrentZoneIndex] = useState('')

  const currentZone = useSelector(selectCurrentZone)

  const changeCurrentZoneIndex = index => {
    if (index === currentZoneIndex) return

    setCurrentZoneIndex(index)
  }

  const withNavigation = !isAtLogin && isLoggedIn

  const restoreLastBusinessesDisplayedColumns = () => {
    const columns = localStorageService.displayedColumns
    const hostColumns = localStorageService.displayedSTRColumns

    if (columns) dispatch(setDisplayedColumns({ columns }))
    if (hostColumns) dispatch(setHostsDisplayedColumns({ columns: hostColumns }))
  }

  // Any other API requests that should only be performed once on initial load of the App can be added inside this method
  const onAppInitialLoad = () => {
    // Sentry.init({ dsn: process.env.SENTRY_DSN });
    const returnUrl = `${pathname}${search}`
    localStorageService.returnUrl = returnUrl

    dispatch(fetchCurrentUser())

    dispatch(fetchUserRoles())
    dispatch(fetchTransmissions())
    dispatch(configureMap())
    dispatch(configurePlatform())
    dispatch(configureStaticFiles())

    restoreLastBusinessesDisplayedColumns()
  }

  useEffect(onAppInitialLoad, [])

  const restoreLastSelectedZone = () => {
    // if (!userService.isSuperuser(currentUser)) return;
    if (!currentUser || !currentUser.email || !availableZones.length) return

    const { email, zoneId } = localStorageService.lastSelectedZoneForUser

    if (email !== currentUser.email) {
      if (availableZones?.length) dispatch(setCurrentZone({ zone: availableZones[0] }))
      return
    }

    const lastZoneId = parseInt(zoneId)
    const nextZone = availableZones.find(({ id }) => id === lastZoneId)

    dispatch(setCurrentZone({ zone: nextZone }))
  }

  // const isLoginMode = () =>
  // String(process.env.LOCAL_LOGIN).toLowerCase() === 'false' && String(process.env.SERVICE).toLowerCase() === 'login';

  // const isMainMode = () =>
  // String(process.env.LOCAL_LOGIN).toLowerCase() === 'false' && String(process.env.SERVICE).toLowerCase() === 'main';

  // console.log('isLoginMode', isLoginMode());
  // console.log('isMainMode', isMainMode());
  // console.log(
  // 'login should be presented',
  // String(process.env.LOCAL_LOGIN).toLowerCase() === 'false' &&
  // String(process.env.SERVICE).toLowerCase() === 'login' &&
  //! isLoggedIn
  // );
  // console.log('LOCAL_LOGIN', process.env.LOCAL_LOGIN);
  // console.log('REACT_APP_LOCAL_LOGIN', process.env.REACT_APP_LOCAL_LOGIN);
  // console.log('SERVICE', process.env.SERVICE);
  // console.log('SERVICE', process.env);
  // console.log('SERVICE', process.env.SERVICE);

  useEffect(() => {
    restoreLastSelectedZone()
    const name = currentUser ? currentUser.email : undefined
    pushInstruction('setUserId', name)
  }, [currentUser, availableZones.length])

  const theme = createTheme(globalTheme)

  const allRoles = Object.values(ROLES)

  return (
    <QueryParamProvider ReactRouterRoute={Route}>
      <div className={styles.appContainer}>
        <Alerts />
        <Header currentZoneIndex={currentZoneIndex} />
        {withNavigation && <Navigation />}
        <main
          className={cx(
            styles.page,
            { [styles.newPage]: location.pathname.includes('/vertical') },
            styles[pathname.substring(1).split('/')[0]],
            { [styles.withNav]: withNavigation },
            { [styles['data-science']]: location.pathname.includes('/vertical/data-science') },
            { [styles['company-overview']]: location.pathname.includes('/vertical/company-overview') },
          )}
        >
          <ErrorBoundary>
            <Switch>
              <Route exact path='/'>
                <Redirect to='/dashboard' />
              </Route>
              <Route path='/login' component={LoginPage} />
              <>
                <PrivateRoute allowedRoles={allRoles} exact path='/dashboard'>
                  <DashboardPage />
                </PrivateRoute>
                <PrivateRoute allowedRoles={allRoles} path='/error/:errorCode'>
                  <ErrorPage />
                </PrivateRoute>
                <PrivateRoute allowedRoles={allRoles} exact path='/preview'>
                  <Preview />
                </PrivateRoute>
                <PrivateRoute allowedRoles={allRoles} path='/management'>
                  <OrganizationsPage />
                </PrivateRoute>
                <PrivateRoute allowedRoles={allRoles} path='/profile' component={ProfilePage} />
                <PrivateRoute allowedRoles={allRoles} exact path='/businesses'>
                  <BusinessesPage />
                </PrivateRoute>
                {currentZone?.airbnbIndices && (
                  <PrivateRoute allowedRoles={allRoles} path='/rentals'>
                    <RentalsPage />
                  </PrivateRoute>
                )}
                <PrivateRoute allowedRoles={allRoles} path='/businesses/:businessId'>
                  <BusinessPage />
                </PrivateRoute>
                <PrivateRoute allowedRoles={allRoles} path='/singleBusiness/:businessId'>
                  <BusinessPage />
                </PrivateRoute>

                <QueryClientProvider client={queryClient}>
                  <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <PrivateRoute allowedRoles={allRoles} path='/vertical/influencers/:entityId'>
                      <VerticalContextProvider>
                        <InfluencersVerticalPage changeCurrentZoneIndex={changeCurrentZoneIndex} />
                      </VerticalContextProvider>
                    </PrivateRoute>
                    <PrivateRoute allowedRoles={allRoles} path='/vertical/ecommerce/:entityId'>
                      <VerticalContextProvider>
                        <EcommerceVerticalPage changeCurrentZoneIndex={changeCurrentZoneIndex} />
                      </VerticalContextProvider>
                    </PrivateRoute>
                    <PrivateRoute allowedRoles={allRoles} path='/vertical/freelancers/:entityId'>
                      <VerticalContextProvider>
                        <FreelancersVerticalPage changeCurrentZoneIndex={changeCurrentZoneIndex} />
                      </VerticalContextProvider>
                    </PrivateRoute>
                    <PrivateRoute allowedRoles={allRoles} path='/vertical/service-providers/:entityId'>
                      <VerticalContextProvider>
                        <ServiceProvidersVerticalPage changeCurrentZoneIndex={changeCurrentZoneIndex} />
                      </VerticalContextProvider>
                    </PrivateRoute>
                    <PrivateRoute allowedRoles={allRoles} path='/vertical/data-science/:entityId'>
                      <VerticalContextProvider>
                        <CDTFAVerticalPage changeCurrentZoneIndex={changeCurrentZoneIndex} />
                      </VerticalContextProvider>
                    </PrivateRoute>
                    <PrivateRoute allowedRoles={allRoles} path='/vertical/company-overview/:caseId'>
                      <VerticalContextProvider>
                        <CompanyOverviewVerticalPage changeCurrentZoneIndex={changeCurrentZoneIndex} />
                      </VerticalContextProvider>
                    </PrivateRoute>
                    <PrivateRoute allowedRoles={allRoles} path='/vertical/nft/:entityId'>
                      <VerticalContextProvider>
                        <NFTVerticalPage changeCurrentZoneIndex={changeCurrentZoneIndex} />
                      </VerticalContextProvider>
                    </PrivateRoute>
                    {currentZone?.strIndices && currentZone?.strActivityIndices && (
                      <PrivateRoute allowedRoles={allRoles} path='/vertical/short-term-rentals/:entityId'>
                        <VerticalContextProvider>
                          <STRVerticalPage changeCurrentZoneIndex={changeCurrentZoneIndex} />
                        </VerticalContextProvider>
                      </PrivateRoute>
                    )}

                    <PrivateRoute allowedRoles={allRoles} path='/influencers'>
                      <SelectedRowsProvider>
                        <InfluencersTablePage />
                      </SelectedRowsProvider>
                    </PrivateRoute>
                    <PrivateRoute allowedRoles={allRoles} path='/ecommerce'>
                      <SelectedRowsProvider>
                        <EcommerceTablePage />
                      </SelectedRowsProvider>
                    </PrivateRoute>
                    <PrivateRoute allowedRoles={allRoles} path='/freelancers'>
                      <SelectedRowsProvider>
                        <FreelancersTablePage />
                      </SelectedRowsProvider>
                    </PrivateRoute>
                    <PrivateRoute allowedRoles={allRoles} path='/service-providers'>
                      <SelectedRowsProvider>
                        <ServiceProvidersTablePage />
                      </SelectedRowsProvider>
                    </PrivateRoute>
                    <PrivateRoute allowedRoles={allRoles} path='/nft'>
                      <SelectedRowsProvider>
                        <NFTTablePage changeCurrentZoneIndex={changeCurrentZoneIndex} />
                      </SelectedRowsProvider>
                    </PrivateRoute>
                    <PrivateRoute allowedRoles={allRoles} path='/data-science'>
                      <SelectedRowsProvider>
                        <CDTFATablePage />
                      </SelectedRowsProvider>
                    </PrivateRoute>
                    <PrivateRoute allowedRoles={allRoles} path='/offshore'>
                      <SelectedRowsProvider>
                        <ZoneApiWrapper>
                          <OffshoreTablePage />
                        </ZoneApiWrapper>
                      </SelectedRowsProvider>
                    </PrivateRoute>
                    <PrivateRoute allowedRoles={allRoles} path='/company-overview'>
                      <SelectedRowsProvider>
                        <CompanyOverviewTablePage />
                      </SelectedRowsProvider>
                    </PrivateRoute>
                    <PrivateRoute allowedRoles={allRoles} path='/fatca'>
                      <SelectedRowsProvider>
                        <ZoneApiWrapper>
                          <FatcaTablePage />
                        </ZoneApiWrapper>
                      </SelectedRowsProvider>
                    </PrivateRoute>
                    {currentZone?.strIndices && currentZone?.strActivityIndices && (
                      <PrivateRoute allowedRoles={allRoles} path='/short-term-rentals'>
                        <SelectedRowsProvider>
                          <STRTablePage />
                        </SelectedRowsProvider>
                      </PrivateRoute>
                    )}
                  </ThemeProvider>
                </QueryClientProvider>
              </>
              <PrivateRoute allowedRoles={allRoles}>
                <NotFoundPage />
              </PrivateRoute>
            </Switch>
          </ErrorBoundary>
        </main>
        {isGlobalLoading && (
          <Loader type='globalLoading' message={isLoggingIn ? intl.formatMessage(messages.loggingIn) : ''} />
        )}
        {isSettingsModalDisplayed && <SettingsModal />}
        {isUserPlatformsModalDisplayed && <PlatformsModal />}
      </div>
    </QueryParamProvider>
  )
}

App.propTypes = {
  intl: PropTypes.object.isRequired,
}

export default injectIntl(App)
