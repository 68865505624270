import React, { useContext, useEffect, useState, FC } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { VerticalContext } from 'store/verticals/Context'
import ActivityOverviewCard from 'containers/VerticalPage/common/ActivityOverviewCard/ActivityOverviewCard'
import VerticalCard from 'containers/VerticalPage/common/VerticalCard/VerticalCard'
import RevenueSourcesOverview from 'containers/VerticalPage/common/RevenueSourcesOverview/RevenueSourcesOverview'
import EntityCard from 'components/EntityCard/EntityCard'
import GenericCarousel from 'components/common/GenericCarousel'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import {
  ActivityOverviewCardLoader,
  EntityCardLoader,
  VerticalCarouselLoader,
} from 'containers/VerticalPage/common/Loaders'
import { selectCurrentZone } from 'store/global'
import type { CurrentZone } from 'utils/types'

const Content: FC = () => {
  const { entityId } = useParams<{ entityId: string }>()
  const { getVerticalEntity, verticalEntity } = useContext(VerticalContext)
  const [isLoading, setIsLoading] = useState(false)
  const currentZone: CurrentZone = useSelector(selectCurrentZone)

  useEffect(() => {
    if (!entityId || !currentZone) return

    if (currentZone.strIndices) {
      const fetchPersonCardData = async () => {
        setIsLoading(true)
        await getVerticalEntity(entityId, currentZone.strIndices)
        setIsLoading(false)
      }
      fetchPersonCardData()
    }
  }, [currentZone, entityId])

  const renderCarouselCards = () => {
    return verticalEntity?.revenueSources?.length ? (
      verticalEntity?.revenueSources?.map(source => (
        <Stack
          direction='row'
          key={source.profileUrl}
          sx={{
            justifyContent: 'space-between',
            mb: '0',
          }}
        >
          <VerticalCard
            platform={source.platform}
            profileName={source.profileName}
            profileImage={source.profileImageUrl}
            profileUrl={source.profileUrl}
            associates={source.associates}
          >
            <RevenueSourcesOverview revenueSources={source.overview} />
          </VerticalCard>
        </Stack>
      ))
    ) : (
      <VerticalCarouselLoader />
    )
  }

  return (
    <Box
      sx={{
        gridColumn: '1 / 2',
        gridRow: '1 / 2',
        display: 'grid',
        gridTemplateColumns: '32% minmax(0, 1fr)',
        gridTemplateRows: 'auto',
        gap: { md: '12px 18px', lg: '12px 22px', xl: '18px 25px', xxl: '28px', xxxl: '30px' },
      }}
    >
      {verticalEntity?.entityDetails ? (
        <EntityCard
          tin={verticalEntity.entityDetails.tin}
          name={verticalEntity.entityDetails.name}
          image={verticalEntity.entityDetails.image}
          profileUrls={verticalEntity.entityDetails.profileUrls}
          location={verticalEntity.entityDetails.location}
          birthday={verticalEntity.entityDetails.birthday}
        />
      ) : (
        isLoading && <EntityCardLoader />
      )}

      {verticalEntity?.overviewDetails ? (
        <ActivityOverviewCard overviewData={verticalEntity.overviewDetails} />
      ) : (
        isLoading && <ActivityOverviewCardLoader />
      )}

      {verticalEntity ? (
        <GenericCarousel slidesCount={verticalEntity?.revenueSources?.length || 0}>
          {renderCarouselCards()}
        </GenericCarousel>
      ) : (
        isLoading && <VerticalCarouselLoader />
      )}
    </Box>
  )
}

export default Content
