import React, { FC, useEffect } from 'react'
import Content from '../Content/Content'
import VerticalDetailsContextProvider from 'store/verticalsDetails/Context'
import Details from 'containers/VerticalPage/ecommerce/Details/Details'
import VerticalPageWrapper from 'containers/VerticalPage/common/VerticalPageWrapper/VerticalPageWrapper'
import { CurrentZone } from 'utils/types'
import { useSelector } from 'react-redux'
import { selectCurrentZone } from 'store/global'
import { changeCurrentZoneIndexFunc } from 'containers/VerticalPage/common/utils'

const VerticalPage = (props: { changeCurrentZoneIndex: changeCurrentZoneIndexFunc }) => {
  const currentZone: CurrentZone = useSelector(selectCurrentZone)

  useEffect(() => {
    props.changeCurrentZoneIndex(currentZone?.ecommerceIndices)
  }, [currentZone])

  return (
    <VerticalPageWrapper
      gridContainerSx={{
        gridTemplateColumns: '100%',
      }}
    >
      <Content />
      <VerticalDetailsContextProvider>
        <Details />
      </VerticalDetailsContextProvider>
    </VerticalPageWrapper>
  )
}

export default VerticalPage
