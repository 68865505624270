export const allBusinessSources = [
  'airbnb',
  'amazon',
  'angies',
  'angieslist',
  'bbb',
  'buildzoom',
  'buildzoompermit',
  'businessrequest',
  'craigslist',
  'discord',
  'etherscan',
  'etsy',
  'facebook',
  'flickr',
  'googlemaps',
  'gmaps',
  'google',
  'googleads',
  'graph',
  'houzz',
  'instagram',
  'opensea',
  'linkedin',
  'linktree',
  'linktr',
  'paypal',
  'pinterest',
  'rarible',
  'snapchat',
  'tiktok',
  'tripadvisor',
  'twitch',
  'twitter',
  'upwork',
  'fiverr',
  'vimeo',
  'wantedly',
  'yelp',
  'youtube',
  'nft',
  'influencers',
  'onlyfans',
  'shopify',
  'ecommerce',
  'gig_economy',
  'midrag',
  'SERVICE_PROVIDER',
  'str',
  'globe',
]
export const [
  SOURCE_AIRBNB,
  SOURCE_AMAZON,
  SOURCE_ANGIES_LIST,
  SOURCE_ANGIES_LIST_ALTERNATIVE,
  SOURCE_BBB,
  SOURCE_BUILDZOOM,
  SOURCE_BUILDZOOM_PERMIT,
  SOURCE_BUSINESS_REQUEST,
  SOURCE_CRAIGSLIST,
  SOURCE_DISCORD,
  SOURCE_ETHERSCAN,
  SOURCE_ETSY,
  SOURCE_FACEBOOK,
  SOURCE_FLICKR,
  SOURCE_GOOGLE_MAPS,
  SOURCE_GOOGLE_MAPS_ALTERNATIVE,
  SOURCE_GOOGLE,
  SOURCE_GOOGLE_ADS,
  SOURCE_GRAPH,
  SOURCE_HOUZZ,
  SOURCE_INSTAGRAM,
  SOURCE_OPENSEA,
  SOURCE_LINKEDIN,
  SOURCE_LINKTREE,
  SOURCE_LINKTREE_ALTERNATIVE,
  SOURCE_PAYPAL,
  SOURCE_PINTEREST,
  SOURCE_RARIBLE,
  SOURCE_SNAPCHAT,
  SOURCE_TIKTOK,
  SOURCE_TRIPADVISOR,
  SOURCE_TWITCH,
  SOURCE_TWITTER,
  SOURCE_UPWORK,
  SOURCE_FIVERR,
  SOURCE_VIMEO,
  SOURCE_WANTEDLY,
  SOURCE_YELP,
  SOURCE_YOUTUBE,
  SOURCE_NFT,
  SOURCE_INFLUENCERS,
  SOURCE_ONLYFANS,
  SOURCE_SHOPIFY,
  SOURCE_ECOMMERCE,
  SOURCE_FREELANCERS,
  SOURCE_MIDRAG,
  SERVICE_PROVIDER,
  SOURCE_STR,
  SOURCE_GLOBE,
] = allBusinessSources

export const allowedSources = [
  SOURCE_AIRBNB,
  SOURCE_AMAZON,
  SOURCE_ANGIES_LIST,
  SOURCE_ANGIES_LIST_ALTERNATIVE,
  SOURCE_BBB,
  SOURCE_BUILDZOOM,
  SOURCE_BUILDZOOM_PERMIT,
  SOURCE_BUSINESS_REQUEST,
  SOURCE_CRAIGSLIST,
  SOURCE_DISCORD,
  SOURCE_ETHERSCAN,
  SOURCE_ETSY,
  SOURCE_FACEBOOK,
  SOURCE_FLICKR,
  SOURCE_GOOGLE_MAPS,
  SOURCE_GOOGLE_MAPS_ALTERNATIVE,
  SOURCE_GOOGLE,
  SOURCE_GOOGLE_ADS,
  SOURCE_GRAPH,
  SOURCE_HOUZZ,
  SOURCE_INSTAGRAM,
  SOURCE_LINKEDIN,
  SOURCE_LINKTREE,
  SOURCE_LINKTREE_ALTERNATIVE,
  SOURCE_OPENSEA,
  SOURCE_PAYPAL,
  SOURCE_PINTEREST,
  SOURCE_RARIBLE,
  SOURCE_SNAPCHAT,
  SOURCE_TIKTOK,
  SOURCE_TRIPADVISOR,
  SOURCE_TWITCH,
  SOURCE_TWITTER,
  SOURCE_UPWORK,
  SOURCE_VIMEO,
  SOURCE_WANTEDLY,
  SOURCE_YELP,
  SOURCE_YOUTUBE,
  SOURCE_NFT,
  SOURCE_INFLUENCERS,
  SOURCE_ONLYFANS,
  SOURCE_SHOPIFY,
  SOURCE_ECOMMERCE,
  SOURCE_FREELANCERS,
  SOURCE_MIDRAG,
  SERVICE_PROVIDER,
  SOURCE_STR,
  SOURCE_GLOBE,
]

export enum ICON_PATHS {
  CLICKABLE = './assets/clickable',
  DISABLED = './assets/disabled',
  ORIGINAL = './assets/original',
}

export const disabledSourceToImageMap = {
  [SOURCE_AIRBNB]: require(`${ICON_PATHS.DISABLED}/airbnb.svg`),
  [SOURCE_AMAZON]: require(`${ICON_PATHS.DISABLED}/amazon.svg`),
  [SOURCE_ANGIES_LIST]: require(`${ICON_PATHS.DISABLED}/angiesList.svg`),
  [SOURCE_ANGIES_LIST_ALTERNATIVE]: require(`${ICON_PATHS.DISABLED}/angiesList.svg`),
  [SOURCE_BBB]: require(`${ICON_PATHS.DISABLED}/bbb.svg`),
  [SOURCE_BUILDZOOM]: require(`${ICON_PATHS.DISABLED}/buildzoom.svg`),
  [SOURCE_BUILDZOOM_PERMIT]: require(`${ICON_PATHS.DISABLED}/buildzoom.svg`),
  [SOURCE_CRAIGSLIST]: require(`${ICON_PATHS.DISABLED}/craigslist.svg`),
  [SOURCE_DISCORD]: require(`${ICON_PATHS.DISABLED}/discord.svg`),
  [SOURCE_ETHERSCAN]: require(`${ICON_PATHS.DISABLED}/etherscan.svg`),
  [SOURCE_ETSY]: require(`${ICON_PATHS.DISABLED}/etsy.svg`),
  [SOURCE_FACEBOOK]: require(`${ICON_PATHS.DISABLED}/facebook.svg`),
  [SOURCE_FLICKR]: require(`${ICON_PATHS.DISABLED}/flickr.svg`),
  [SOURCE_GOOGLE_MAPS]: require(`${ICON_PATHS.DISABLED}/gmaps.svg`),
  [SOURCE_GOOGLE_MAPS_ALTERNATIVE]: require(`${ICON_PATHS.DISABLED}/gmaps.svg`),
  [SOURCE_GOOGLE]: require(`${ICON_PATHS.DISABLED}/google.svg`),
  [SOURCE_GOOGLE_ADS]: require(`${ICON_PATHS.DISABLED}/googleAds.svg`),
  [SOURCE_GRAPH]: require(`${ICON_PATHS.DISABLED}/globe.svg`),
  [SOURCE_HOUZZ]: require(`${ICON_PATHS.DISABLED}/houzz.svg`),
  [SOURCE_INSTAGRAM]: require(`${ICON_PATHS.DISABLED}/instagram.svg`),
  [SOURCE_LINKEDIN]: require(`${ICON_PATHS.DISABLED}/linkedin.svg`),
  [SOURCE_LINKTREE]: require(`${ICON_PATHS.DISABLED}/linktree.svg`),
  [SOURCE_LINKTREE_ALTERNATIVE]: require(`${ICON_PATHS.DISABLED}/linktree.svg`),
  [SOURCE_OPENSEA]: require(`${ICON_PATHS.DISABLED}/opensea.svg`),
  [SOURCE_PAYPAL]: require(`${ICON_PATHS.DISABLED}/paypal.svg`),
  [SOURCE_PINTEREST]: require(`${ICON_PATHS.DISABLED}/pinterest.svg`),
  [SOURCE_RARIBLE]: require(`${ICON_PATHS.DISABLED}/rarible.svg`),
  [SOURCE_SNAPCHAT]: require(`${ICON_PATHS.DISABLED}/snapchat.svg`),
  [SOURCE_TIKTOK]: require(`${ICON_PATHS.DISABLED}/tiktok.svg`),
  [SOURCE_TRIPADVISOR]: require(`${ICON_PATHS.DISABLED}/tripadvisor.svg`),
  [SOURCE_TWITCH]: require(`${ICON_PATHS.DISABLED}/twitch.svg`),
  [SOURCE_TWITTER]: require(`${ICON_PATHS.DISABLED}/twitter.svg`),
  [SOURCE_UPWORK]: require(`${ICON_PATHS.DISABLED}/upwork.svg`),
  [SOURCE_FIVERR]: require(`${ICON_PATHS.DISABLED}/fiverr.svg`),
  [SOURCE_VIMEO]: require(`${ICON_PATHS.DISABLED}/vimeo.svg`),
  [SOURCE_WANTEDLY]: require(`${ICON_PATHS.DISABLED}/wantedly.svg`),
  [SOURCE_YELP]: require(`${ICON_PATHS.DISABLED}/yelp.svg`),
  [SOURCE_YOUTUBE]: require(`${ICON_PATHS.DISABLED}/youtube.svg`),
  [SOURCE_ONLYFANS]: require(`${ICON_PATHS.DISABLED}/onlyfans.svg`),
  [SOURCE_MIDRAG]: require(`${ICON_PATHS.DISABLED}/midrag.svg`),
}

export const clickableSourceToImageMap = {
  [SOURCE_AIRBNB]: require(`${ICON_PATHS.CLICKABLE}/airbnb.svg`),
  [SOURCE_AMAZON]: require(`${ICON_PATHS.CLICKABLE}/amazon.svg`),
  [SOURCE_ANGIES_LIST]: require(`${ICON_PATHS.CLICKABLE}/angiesList.svg`),
  [SOURCE_ANGIES_LIST_ALTERNATIVE]: require(`${ICON_PATHS.CLICKABLE}/angiesList.svg`),
  [SOURCE_BBB]: require(`${ICON_PATHS.CLICKABLE}/bbb.svg`),
  [SOURCE_BUILDZOOM]: require(`${ICON_PATHS.CLICKABLE}/buildzoom.svg`),
  [SOURCE_BUILDZOOM_PERMIT]: require(`${ICON_PATHS.CLICKABLE}/buildzoom.svg`),
  [SOURCE_BUSINESS_REQUEST]: require(`${ICON_PATHS.CLICKABLE}/businessRequests.svg`),
  [SOURCE_CRAIGSLIST]: require(`${ICON_PATHS.CLICKABLE}/craigslist.svg`),
  [SOURCE_DISCORD]: require(`${ICON_PATHS.CLICKABLE}/discord.svg`),
  [SOURCE_ETHERSCAN]: require(`${ICON_PATHS.CLICKABLE}/etherscan.svg`),
  [SOURCE_ETSY]: require(`${ICON_PATHS.CLICKABLE}/etsy.svg`),
  [SOURCE_FACEBOOK]: require(`${ICON_PATHS.CLICKABLE}/facebook.svg`),
  [SOURCE_FLICKR]: require(`${ICON_PATHS.CLICKABLE}/flickr.svg`),
  [SOURCE_GOOGLE_MAPS]: require(`${ICON_PATHS.CLICKABLE}/gmaps.svg`),
  [SOURCE_GOOGLE_MAPS_ALTERNATIVE]: require(`${ICON_PATHS.CLICKABLE}/gmaps.svg`),
  [SOURCE_GOOGLE]: require(`${ICON_PATHS.CLICKABLE}/google.svg`),
  [SOURCE_GOOGLE_ADS]: require(`${ICON_PATHS.CLICKABLE}/googleAds.svg`),
  [SOURCE_GRAPH]: require(`${ICON_PATHS.CLICKABLE}/globe.svg`),
  [SOURCE_HOUZZ]: require(`${ICON_PATHS.CLICKABLE}/houzz.svg`),
  [SOURCE_INSTAGRAM]: require(`${ICON_PATHS.CLICKABLE}/instagram.svg`),
  [SOURCE_LINKEDIN]: require(`${ICON_PATHS.CLICKABLE}/linkedin.svg`),
  [SOURCE_LINKTREE]: require(`${ICON_PATHS.CLICKABLE}/linktree.svg`),
  [SOURCE_LINKTREE_ALTERNATIVE]: require(`${ICON_PATHS.CLICKABLE}/linktree.svg`),
  [SOURCE_OPENSEA]: require(`${ICON_PATHS.CLICKABLE}/opensea.svg`),
  [SOURCE_PAYPAL]: require(`${ICON_PATHS.CLICKABLE}/paypal.svg`),
  [SOURCE_PINTEREST]: require(`${ICON_PATHS.CLICKABLE}/pinterest.svg`),
  [SOURCE_RARIBLE]: require(`${ICON_PATHS.CLICKABLE}/rarible.svg`),
  [SOURCE_SNAPCHAT]: require(`${ICON_PATHS.CLICKABLE}/snapchat.svg`),
  [SOURCE_TIKTOK]: require(`${ICON_PATHS.CLICKABLE}/tiktok.svg`),
  [SOURCE_TRIPADVISOR]: require(`${ICON_PATHS.CLICKABLE}/tripadvisor.svg`),
  [SOURCE_TWITCH]: require(`${ICON_PATHS.CLICKABLE}/twitch.svg`),
  [SOURCE_TWITTER]: require(`${ICON_PATHS.CLICKABLE}/twitter.svg`),
  [SOURCE_UPWORK]: require(`${ICON_PATHS.CLICKABLE}/upwork.svg`),
  [SOURCE_FIVERR]: require(`${ICON_PATHS.CLICKABLE}/fiverr.svg`),
  [SOURCE_VIMEO]: require(`${ICON_PATHS.CLICKABLE}/vimeo.svg`),
  [SOURCE_WANTEDLY]: require(`${ICON_PATHS.CLICKABLE}/wantedly.svg`),
  [SOURCE_YELP]: require(`${ICON_PATHS.CLICKABLE}/yelp.svg`),
  [SOURCE_YOUTUBE]: require(`${ICON_PATHS.CLICKABLE}/youtube.svg`),
  [SOURCE_ONLYFANS]: require(`${ICON_PATHS.CLICKABLE}/onlyfans.svg`),
  [SOURCE_MIDRAG]: require(`${ICON_PATHS.CLICKABLE}/midrag.svg`),
}

export const originalSourceToImageMap = {
  [SOURCE_AIRBNB]: require(`${ICON_PATHS.ORIGINAL}/airbnb.svg`),
  [SOURCE_AMAZON]: require(`${ICON_PATHS.ORIGINAL}/amazon.svg`),
  [SOURCE_ANGIES_LIST]: require(`${ICON_PATHS.ORIGINAL}/angiesList.svg`),
  [SOURCE_ANGIES_LIST_ALTERNATIVE]: require(`${ICON_PATHS.ORIGINAL}/angiesList.svg`),
  [SOURCE_BBB]: require(`${ICON_PATHS.ORIGINAL}/bbb.svg`),
  [SOURCE_BUILDZOOM]: require(`${ICON_PATHS.ORIGINAL}/buildzoom.svg`),
  [SOURCE_BUILDZOOM_PERMIT]: require(`${ICON_PATHS.ORIGINAL}/buildzoom.svg`),
  [SOURCE_CRAIGSLIST]: require(`${ICON_PATHS.ORIGINAL}/craigslist.svg`),
  [SOURCE_DISCORD]: require(`${ICON_PATHS.ORIGINAL}/discord.svg`),
  [SOURCE_ETHERSCAN]: require(`${ICON_PATHS.ORIGINAL}/etherscan.svg`),
  [SOURCE_ETSY]: require(`${ICON_PATHS.ORIGINAL}/etsy.svg`),
  [SOURCE_FACEBOOK]: require(`${ICON_PATHS.ORIGINAL}/facebook.svg`),
  [SOURCE_FLICKR]: require(`${ICON_PATHS.ORIGINAL}/flickr.svg`),
  [SOURCE_GOOGLE_MAPS]: require(`${ICON_PATHS.ORIGINAL}/gmaps.svg`),
  [SOURCE_GOOGLE_MAPS_ALTERNATIVE]: require(`${ICON_PATHS.ORIGINAL}/gmaps.svg`),
  [SOURCE_GOOGLE]: require(`${ICON_PATHS.ORIGINAL}/google.svg`),
  [SOURCE_GOOGLE_ADS]: require(`${ICON_PATHS.ORIGINAL}/googleAds.svg`),
  [SOURCE_GRAPH]: require(`${ICON_PATHS.ORIGINAL}/globe.svg`),
  [SOURCE_HOUZZ]: require(`${ICON_PATHS.ORIGINAL}/houzz.svg`),
  [SOURCE_INSTAGRAM]: require(`${ICON_PATHS.ORIGINAL}/instagram.svg`),
  [SOURCE_LINKEDIN]: require(`${ICON_PATHS.ORIGINAL}/linkedin.svg`),
  [SOURCE_LINKTREE]: require(`${ICON_PATHS.ORIGINAL}/linktree.svg`),
  [SOURCE_LINKTREE_ALTERNATIVE]: require(`${ICON_PATHS.ORIGINAL}/linktree.svg`),
  [SOURCE_OPENSEA]: require(`${ICON_PATHS.ORIGINAL}/opensea.svg`),
  [SOURCE_PAYPAL]: require(`${ICON_PATHS.ORIGINAL}/paypal.svg`),
  [SOURCE_PINTEREST]: require(`${ICON_PATHS.ORIGINAL}/pinterest.svg`),
  [SOURCE_RARIBLE]: require(`${ICON_PATHS.ORIGINAL}/rarible.svg`),
  [SOURCE_SNAPCHAT]: require(`${ICON_PATHS.ORIGINAL}/snapchat.svg`),
  [SOURCE_TIKTOK]: require(`${ICON_PATHS.ORIGINAL}/tiktok.svg`),
  [SOURCE_TRIPADVISOR]: require(`${ICON_PATHS.ORIGINAL}/tripadvisor.svg`),
  [SOURCE_TWITCH]: require(`${ICON_PATHS.ORIGINAL}/twitch.svg`),
  [SOURCE_TWITTER]: require(`${ICON_PATHS.ORIGINAL}/twitter.svg`),
  [SOURCE_UPWORK]: require(`${ICON_PATHS.ORIGINAL}/upwork.svg`),
  [SOURCE_FIVERR]: require(`${ICON_PATHS.ORIGINAL}/fiverr.svg`),
  [SOURCE_VIMEO]: require(`${ICON_PATHS.ORIGINAL}/vimeo.svg`),
  [SOURCE_WANTEDLY]: require(`${ICON_PATHS.ORIGINAL}/wantedly.svg`),
  [SOURCE_YELP]: require(`${ICON_PATHS.ORIGINAL}/yelp.svg`),
  [SOURCE_YOUTUBE]: require(`${ICON_PATHS.ORIGINAL}/youtube.svg`),
  [SOURCE_NFT]: require(`${ICON_PATHS.ORIGINAL}/nft.svg`),
  [SOURCE_INFLUENCERS]: require(`${ICON_PATHS.ORIGINAL}/influencers.svg`),
  [SOURCE_ONLYFANS]: require(`${ICON_PATHS.ORIGINAL}/onlyfans.svg`),
  [SOURCE_SHOPIFY]: require(`${ICON_PATHS.ORIGINAL}/shopify.svg`),
  [SOURCE_ECOMMERCE]: require(`${ICON_PATHS.ORIGINAL}/ecommerce.svg`),
  [SOURCE_FREELANCERS]: require(`${ICON_PATHS.ORIGINAL}/gig_economy.svg`),
  [SOURCE_MIDRAG]: require(`${ICON_PATHS.ORIGINAL}/midrag.svg`),
  [SERVICE_PROVIDER]: require(`${ICON_PATHS.ORIGINAL}/service_provider.svg`),
  [SOURCE_STR]: require(`${ICON_PATHS.ORIGINAL}/str.svg`),
  [SOURCE_GLOBE]: require(`${ICON_PATHS.ORIGINAL}/globe.svg`),
}
