import React, { useEffect, useMemo, useRef, useState } from 'react'
import Alert from '@mui/material/Alert'
import { selectCurrentZone } from '../../store/global'
import NavigationHeader from 'components/NavigationHeader/NavigationHeader'
import GridLoader from 'components/GridLoader/GridLoader'
import TableActionsBars from 'components/verticals/TableActionsBar/TableActionsBar'

import { CurrentZone } from 'utils/types'

import { useCompanyOverviewOverviewQuery } from './useCompanyOverviewOverviewQuery'

import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import cx from 'classnames'
import messages from './messages'

import styles from './CompanyOverviewTablePage.scss'
import { getUrlPageValues, removeKeywordSuffix } from '../VerticalPage/common/utils'
import { NOT_AVAILABLE } from 'utils/constants'
import { Filter, FilterAdditionalData } from 'components/FilterDrawer/FilterDrawer.config'
import useVerticalSearchAndSort from 'hooks/useVerticalSearchAndSort'
import { useDebounce } from 'hooks/useDebounce'

import { ISelectedColumn } from 'components/verticals/VerticalTableBase/types'
import { MRT_ColumnDef } from 'material-react-table'
import { useCompanyOverviewMainTableQuery } from './useCompanyOverviewMainTableQuery'
import CompanyOverviewTable from 'components/CompanyOverview/CompanyOverviewTable'
import { CompanyOverviewEntityForTable, ICompanyOverviewSummary } from './types'
import CompanyOverviewSummary from 'containers/CompanyOverviewTablePage/CompanyOverviewSummary'
import { useFilterOptionsQuery } from 'hooks/useFilterOptionsQuery'
import { filtersInitialState, structureChangeTooltip } from './utils'
import { useEmptyColumnsQuery } from 'hooks/useEmptyColumnsQuery'

const csvFileName = 'Company-Overview-Table'

const CompanyOverviewTablePage = () => {
  const currentZone: CurrentZone = useSelector(selectCurrentZone)
  const [mergedColumns, setMergedColumns] = useState<MRT_ColumnDef<CompanyOverviewEntityForTable>[]>([])
  const [selectedColumns, setSelectedColumns] = useState<ISelectedColumn[]>([])

  const allColumns = useMemo(() => mergedColumns?.flatMap(column => column ?? []), [mergedColumns]) || []

  const { searchValue, searchValueDebounced, sorting, setSearchValue, setSorting } = useVerticalSearchAndSort()

  const [activeFilters, setActiveFilters] = useState<Filter[]>([])
  const [filterAdditionalData, setFilterAdditionalData] = useState<FilterAdditionalData[]>([])

  const activeFiltersDebounced = useDebounce(activeFilters, 500)

  const { data: emptyColumns } = useEmptyColumnsQuery({
    verticalIndices: currentZone?.companyOverviewIndices,
    currentQueryKey: 'companyOverview-entities-key',
    keys: removeKeywordSuffix(allColumns.map(el => el.id || '').filter(key => Boolean(key))),
  })

  // Fetch overview data
  const { data: overviewData } = useCompanyOverviewOverviewQuery({
    verticalIndices: currentZone?.companyOverviewIndices,
    currentQueryKey: 'company-overview-overview-key',
    searchValue: searchValueDebounced,
    filters: activeFiltersDebounced,
  })

  // Fetch entities data
  const { verticalEntities, fetchNextPage, isFetching, isLoading, isError } =
    useCompanyOverviewMainTableQuery<CompanyOverviewEntityForTable>({
      verticalIndices: currentZone?.companyOverviewIndices,
      currentQueryKey: 'company-overview-entities-key',
      searchValue: searchValueDebounced,
      filters: activeFiltersDebounced,
      sorting,
    })

  const { filterOptions } = useFilterOptionsQuery({
    verticalIndices: currentZone?.companyOverviewIndices,
    currentQueryKey: 'company-overview-filter-options-key',
    filtersInitialState,
  })

  const overviewSummaryData = useMemo(() => {
    if (!overviewData) return null
    const overviewDataCopy = { ...overviewData }
    delete overviewDataCopy.total
    return overviewDataCopy
  }, [overviewData])

  useEffect(() => {
    if (overviewData && filterAdditionalData.length === 0) {
      setFilterAdditionalData([structureChangeTooltip])
    }
  }, [overviewData, filterAdditionalData])

  return (
    <div className={styles.container}>
      <nav id='navigationHeaderWrapper' className={styles.navigationHeaderWrapper}>
        <NavigationHeader />
      </nav>
      <header className='page-header withNavigationHeader'>
        <h1 className={cx('page-title', 'h1')}>
          <FormattedMessage {...messages.header} />
        </h1>
      </header>
      <main className={styles.main}>
        <div className={styles.contentWrapper}>
          <section className={styles.summary} id={'businessSummary'}>
            <CompanyOverviewSummary summaryData={overviewSummaryData as ICompanyOverviewSummary} />
          </section>
          <div className={cx(styles.content, { [styles.withFilters]: false })}>
            <div className={styles.gridContainer}>
              <TableActionsBars
                countMessages={messages.count}
                displayedCount={verticalEntities ? verticalEntities.length : null}
                totalCount={overviewData?.total ?? NOT_AVAILABLE}
                zoneEndpoint={currentZone?.companyOverviewIndices}
                csvFileName={csvFileName}
                type={getUrlPageValues()[0]}
                withExport={true}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                mergedColumns={mergedColumns}
                selectedColumns={selectedColumns}
                setSelectedColumns={setSelectedColumns}
                allColumns={allColumns}
                filtersInitialState={filtersInitialState}
                filterOptions={filterOptions}
                activeFilters={activeFilters}
                setActiveFilters={setActiveFilters}
                filterAdditionalData={filterAdditionalData}
                emptyColumns={emptyColumns}
              />
              {isLoading ? (
                <GridLoader />
              ) : isError ? (
                <Alert severity='error' sx={{ fontFamily: 'Source Sans Pro', fontSize: '1rem', alignItems: 'center' }}>
                  Something went wrong
                </Alert>
              ) : (
                <section className={styles.grid}>
                  <CompanyOverviewTable
                    isFetching={isFetching}
                    fetchNextPage={fetchNextPage}
                    totalFetched={verticalEntities?.length || 0}
                    totalDBRowCount={overviewData?.total}
                    verticalEntities={verticalEntities}
                    sorting={sorting}
                    setSorting={setSorting}
                    setMergedColumns={setMergedColumns}
                    selectedColumns={selectedColumns}
                    allColumns={allColumns}
                  />
                </section>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default CompanyOverviewTablePage
