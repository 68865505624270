/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Import all the third party stuff
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import history from 'utils/history'
import 'sanitize.css/sanitize.css'
import 'bootstrap/dist/css/bootstrap.min.css'

import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

// Import root app
import App from 'containers/App'

import './global.scss'
import './fonts/fonts.scss'

// Import Language Provider
import LanguageProvider from 'containers/LanguageProvider'

// Load the favicon and the .htaccess file
/* eslint-disable import/no-unresolved, import/extensions */
import '!file-loader?name=[name].[ext]!./images/logox.svg'
import 'file-loader?name=.htaccess!./.htaccess'
/* eslint-enable import/no-unresolved, import/extensions */

import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react'
import { Router } from 'react-router-dom'

// Import i18n messages
import { translationMessages } from './i18n'
import store from './store'

const MOUNT_NODE = document.getElementById('app')
const root = createRoot(MOUNT_NODE)

console.log('MATOMO_URL', process.env.MATOMO_URL)
console.log('MATOMO_SITE_ID', process.env.MATOMO_SITE_ID)
const instance = createInstance({
  urlBase: process.env.MATOMO_URL,
  siteId: process.env.MATOMO_SITE_ID,
  disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
  heartBeat: {
    // optional, enabled by default
    active: true, // optional, default value: true
    seconds: 10, // optional, default value: `15
  },
  configurations: {
    // optional, default value: {}
    // any valid matomo configuration, all below are optional
    disableCookies: true,
    setSecureCookie: true,
    setRequestMethod: 'POST',
  },
  linkTracking: true,
})

const render = messages =>
  root.render(
    <Provider store={store}>
      <Router history={history}>
        <LanguageProvider messages={messages}>
          <MatomoProvider value={instance}>
            <App />
          </MatomoProvider>
        </LanguageProvider>
      </Router>
    </Provider>,
  )

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise(resolve => {
    resolve(import('intl'))
  })
    .then(() => Promise.all([import('intl/locale-data/jsonp/en.js')]))
    .then(() => render(translationMessages))
    .catch(err => {
      throw err
    })
} else {
  render(translationMessages)
}
