import React, { ReactNode } from 'react'
import { default as MuiTooltip, TooltipProps } from '@mui/material/Tooltip'
import tooltipStyles from './Tooltip.styles'

interface CustomTooltipProps extends Omit<TooltipProps, 'title'> {
  position?: TooltipProps['placement']
  title?: ReactNode
  html?: ReactNode
  theme?: 'light' | 'dark' | 'transparent'
  className?: string
}

const Tooltip = ({ position, title, children, html, theme = 'dark', className, ...rest }: CustomTooltipProps) => {
  const isLightTheme = theme === 'light'

  return (
    <MuiTooltip
      placement={position}
      title={title || html || ''}
      classes={{ tooltip: className }}
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            ...tooltipStyles.tooltip,
            ...(isLightTheme && tooltipStyles.tooltipLight),
          },
        },
        arrow: {
          sx: {
            ...tooltipStyles.tooltipArrow,
            ...(isLightTheme && tooltipStyles.tooltipArrowLight),
          },
        },
      }}
      {...rest}
    >
      <span>{children}</span>
    </MuiTooltip>
  )
}

export default Tooltip
